.grid-container-p {
  display: grid;
  grid-template-columns: 1fr 4fr 4fr 4fr 1fr;
  grid-template-rows: 1fr 4fr 4fr 1fr;
  grid-gap: 2vw;
}
.grid-item-1p {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: end;
  justify-content: center;
}
.grid-item-2p {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1vw;
}
.grid-item-3p {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1vw;
}
.grid-item-4p {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 2;
  grid-row-end: 3;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1vw;
}
.grid-item-5p {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1vw;
}
.grid-item-6p {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 1vw;
}

.projects-title {
  font-size: 4vw;
}
.project-title {
  display: inline-block;
  font-size: 1.5vw;
  margin-left: 1vw;
  margin-right: 1vw;
}
.project-tech {
  color: grey;
  font-size: 1.25vw;
  margin-left: 1vw;
  margin-right: 1vw;
}
.project-content {
  font-size: 1vw;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-bottom: 1vw;
}
.project-img {
  width: 100%;
  height: auto;
}
.github {
  display: inline-block;
  color: black;
  text-decoration: none;
  font-size: 1vw;
  transition: ease-out 0.2s;
}
.github:hover {
  transform: scale(1.1);
  color: gold;
}

@media only screen and (max-width: 767px) {
  .grid-container-p {
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-rows: 1.5fr 5fr auto auto auto auto 1fr;
  }
  .grid-item-2p {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .grid-item-3p {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .grid-item-4p {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .grid-item-5p {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 5;
    grid-row-end: 6;
  }
  .grid-item-6p {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 6;
    grid-row-end: 7;
  }
  .projects-title {
    font-size: 10vw;
  }
  .project-title {
    font-size: 4vw;
  }
  .project-tech {
    font-size: 3vw;
  }
  .project-content {
    font-size: 3vw;
  }
  .github {
    font-size: 3vw;
  }
}
