.nav {
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: #093117;
  width: 100vw;
}
.nav > ul {
  display: flex;
  justify-content: right;
  padding-right: 3vw;
}

.nav > ul > li {
  padding: 1.5vh 3vw 1.5vh 3vw;
}
.nav > ul > li > a {
  text-decoration: none;
  color: white;
  font-size: 1.5vw;
}
.nav > ul > li > a:hover {
  color: gold;
}
.hamburger {
  display: None;
}
.mobile-nav {
  display: None;
}
@media only screen and (max-width: 767px) {
  .nav > ul {
    display: None;
  }
  .hamburger {
    display: flex;
    color: white;
    justify-content: end;
    padding: 1.5vh 5vw 1.5vh 5vw;
  }
  .icon:hover {
    color: gold;
    cursor: pointer;
  }
  .mobile-nav {
    display: flex;
  }
}
