.grid-container {
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 1fr;
  grid-template-rows: 1fr 2fr 1fr;
  grid-gap: 20px;
  background-color: #093117;
  min-height: 100vh;
  min-width: 100vw;
}
.grid-item-1 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;
}
.grid-item-2 {
  display: flex;
  justify-content: center;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
}
.grid-item-3 {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 4;
}
.name {
  color: #ffface;
  font-size: 5vw;
}
.welcome {
  color: white;
  font-size: 2vw;
}
.role {
  color: white;
  font-size: 2vw;
}
.degree {
  color: white;
  font-size: 1.5vw;
}
.logos {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  width: 40vw;
}
.logo-style {
  color: white;
  text-decoration: none;
  font-size: 1.5vw;
}
.logo-style:hover {
  color: #ffd700;
}

.img {
  border-radius: 50%;
  width: 20vw;
  height: auto;
  align-self: center;
  transition: ease-out 0.2s;
}
.img:hover {
  box-shadow: 0 0 20px 5px green;
  transform: scale(1.05);
}
.button-container {
  padding-bottom: 20px;
}
.resume-button {
  width: 10vw;
  border-style: solid;
  border-width: thin;
  border-color: #ffd700;
  background-color: #093117;
  color: #ffd700;
  box-shadow: inset 0 0 0 0 #ffd700;
  transition: ease-out 0.3s;
  text-decoration: none;
  font-size: 1.5vw;
}
.resume-button:hover {
  box-shadow: inset 10vw 0 0 0 #ffd700;
  color: #093117;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 1fr 2.5fr 3fr 1fr;
  }
  .grid-item-1 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .grid-item-2 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .grid-item-3 {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .name {
    font-size: 7.5vw;
  }
  .welcome {
    font-size: 4vw;
  }
  .role {
    font-size: 4vw;
  }
  .degree {
    font-size: 3vw;
  }
  .logos {
    width: 50vw;
  }
  .img {
    width: 30vw;
  }
  .logo-style {
    font-size: 2vw;
  }
  .resume-button {
    font-size: 3vw;
    width: 20vw;
  }
  .resume-button:hover {
    box-shadow: inset 20vw 0 0 0 #ffd700;
  }
}
