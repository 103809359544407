.contact-title {
  text-align: center;
  font-size: 4vw;
  margin-top: 8vh;
}
.form-container {
  display: flex;
  justify-content: space-around;
}
.form {
  width: 70vw;
  height: 70vh;
  margin-bottom: 10vh;
}
.message {
  font-size: 1.5vw;
  margin: 5vh 15vw 5vh 15vw;
}
.email {
  color: black;
}
@media only screen and (max-width: 767px) {
  .contact-title {
    font-size: 10vw;
  }
  .message {
    font-size: 3vw;
  }
}
